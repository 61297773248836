import * as React from "react";
import Countdown from "react-countdown";
import "react-simple-keyboard/build/css/index.css";
import { GuessStatus, ModalTypes, Result } from "./enums";
import Modal from "react-modal";
import { ModalContent } from "./ModalContent";
import { Stars } from "./Stars";
import { CustomKeyboard } from "./CustomKeyboard";
import { AddClue } from "./pages/AddClue";
import { getClueForToday, manageGame } from "../services/AppService";
import { BeatLoader } from "react-spinners";
import { JordanSecretPage } from "./pages/JordanSecretPage";

// NOTES:
// - still need to alter (unused) stuff in DB to match new data types

const GAME_TITLE = "ACRONYX";
const CRYSTAL_BALL = "CRYSTALBALL";
const J_SANGRIA = "JSANGRIA";

const formatDateString = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export interface Clue {
  Id: number;
  ClueLetters: string;
  ClueDate: string;
  SolutionWord: string;
  SolutionExplanation: string;
  Hint: string;
}

const InitialClue: Clue = {
  Id: 0,
  ClueLetters: '',
  ClueDate: '',
  SolutionWord: '',
  SolutionExplanation: '',
  Hint: '',
}

export interface GameData {
  GameId: number;
  Clue: Clue;
  PlayerGuid: string;
  IsGameOver: boolean;
  GameOverReason: Result;
  CurrentScore: number;
  GuessedLetters: string[];
  IsHintUsed: boolean;
}

const InitialGameData: GameData = {
  GameId: 0,
  Clue: { ...InitialClue },
  PlayerGuid: '',
  IsGameOver: false,
  GameOverReason: Result.None,
  CurrentScore: 3,
  GuessedLetters: [],
  IsHintUsed: false,
};

export interface PlayerData {
  TotalScore: number;
  GamesPlayed: number;
  Streak: number;
  PlayerGuid: string;
}

const InitialPlayerData: PlayerData = {
  TotalScore: 0,
  GamesPlayed: 0,
  Streak: 0,
  PlayerGuid: ''
};

export interface HomeData {
  ClueData: Clue;
  GameData: GameData;
  PlayerData: PlayerData;
}

export function Game() {
  Modal.setAppElement("body");

  const [input, setInput] = React.useState<string>("");
  const [guessStatus, setGuessStatus] = React.useState<GuessStatus>(GuessStatus.None);
  const [disableGuess, setDisableGuess] = React.useState<boolean>(false);
  const [showGameOverDisplay, setShowGameOverDisplay] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [modalType, setModalType] = React.useState<ModalTypes | undefined>(undefined);
  const [playerData, setPlayerData] = React.useState<PlayerData>({ ...InitialPlayerData });
  const [gameData, setGameData] = React.useState<GameData>({ ...InitialGameData });
  const [removedLetters, setRemovedLetters] = React.useState<string>("");
  const [minusOneVisible, setMinusOneVisible] = React.useState<boolean>(false);
  const [secretInput, setSecretInput] = React.useState<string>("");
  const [showSecretIcon, setShowSecretIcon] = React.useState<boolean>(false);
  const [showAddClueForm, setShowAddClueForm] = React.useState<boolean>(false);
  const [showJordanSecretPage, setShowJordanSecretPage] = React.useState<boolean>(false);
  const [ClueOfTheDay, setClueOfTheDay] = React.useState<Clue | undefined>(undefined);
  const [solutionLetter, setSolutionLetter] = React.useState<string>("");
  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

  const generateRemovedLetters = (guessedLetters: string[]): string => {
    let removedLetters = "";

    guessedLetters.forEach((letter) => {
      removedLetters += " " + letter.toUpperCase() + " " + letter.toLowerCase();
    });

    return removedLetters;
  }

  const showMinusOne = () => {
    setMinusOneVisible(true);

    setTimeout(() => {
      setMinusOneVisible(false);
    }, 2500);
  };

  const flushOldData = () => {
    const lsData = localStorage.getItem(GAME_TITLE);

    if(lsData) {
      localStorage.removeItem(GAME_TITLE);
    }
  }

  const getGameDataLS = (): GameData => {
    const gameDataLS = localStorage.getItem(`${GAME_TITLE}-game`);

    return gameDataLS ? JSON.parse(gameDataLS) : null;
  }

  const getPlayerDataLS = (): PlayerData => {
    const playerDataLS = localStorage.getItem(`${GAME_TITLE}-player`);

    return playerDataLS ? JSON.parse(playerDataLS) : null;
  }

  const updateGameDataLS = React.useCallback((gameData: GameData) => {
    localStorage.setItem(`${GAME_TITLE}-game`, JSON.stringify({ ...gameData, PlayerGuid: playerData.PlayerGuid }));
  }, [playerData.PlayerGuid]);

  const updatePlayerDataLS = (playerData: PlayerData) => {
    localStorage.setItem(`${GAME_TITLE}-player`, JSON.stringify({ ...playerData }));
  }

  React.useEffect(() => {
    const formattedDate: string = formatDateString(new Date());

    const initializePlayerData = (): void => {
      if(getPlayerDataLS()) {
        setPlayerData(getPlayerDataLS());
      }
      else {
        const newPlayerData: PlayerData = { ...InitialPlayerData, PlayerGuid: crypto.randomUUID() };
  
        setPlayerData(newPlayerData);
        updatePlayerDataLS(newPlayerData);
        
        setTimeout(() => {
          openModal(ModalTypes.Help);
        }, 1000);
      }
    }

    // Clear old 'ACRONYX' LS data
    flushOldData();

    // Initialize game data
    const gameDataLS: GameData = getGameDataLS();

    if(gameDataLS && gameDataLS.Clue.ClueDate === formattedDate) {
      setGameData({ ...gameDataLS });
      setClueOfTheDay(gameDataLS.Clue);
      setSolutionLetter(gameDataLS.Clue.SolutionWord.charAt(0));
      setRemovedLetters(generateRemovedLetters(gameDataLS.GuessedLetters));

      if(gameDataLS.IsGameOver) {
        setInput(gameDataLS.Clue.SolutionWord.charAt(0).toUpperCase());
        setShowGameOverDisplay(true);

        if(gameDataLS.GameOverReason === Result.Win) {
          setGuessStatus(GuessStatus.Correct);
        }
      }

      initializePlayerData();
      setDataLoaded(true);
    }
    else {
      getClueForToday(formattedDate).then((returnedClue: Clue) => {
        const newGameData: GameData = { ...InitialGameData, Clue: returnedClue };
  
        setGameData(newGameData);
        setClueOfTheDay(returnedClue);
        setSolutionLetter(returnedClue.SolutionWord.charAt(0));
        updateGameDataLS(newGameData);
  
        initializePlayerData();
        setDataLoaded(true);
      });
    }
  }, [updateGameDataLS]);

  React.useEffect(() => {
    if(ClueOfTheDay) {
      const textContainer = document.getElementById("wave-container");
      
      if (!textContainer?.textContent && !textContainer?.childNodes.length) {
        ClueOfTheDay.ClueLetters.split("").forEach((char, index) => {
          const span = document.createElement("span");
          span.textContent = char;
          span.classList.add("wave-text");
          span.style.animationDelay = `${index * 50}ms`;
          textContainer?.appendChild(span);
        });
      }
    }
  }, [ClueOfTheDay]);

  React.useEffect(() => {
    if(secretInput && (secretInput.toUpperCase() === J_SANGRIA || secretInput.toUpperCase() === CRYSTAL_BALL)) {
      setShowSecretIcon(true);
    }
    else {
      setShowSecretIcon(false);
    }
  }, [secretInput]);

  const updateGuessStatus = (status: GuessStatus, currentScore: number): void => {
    if (currentScore !== 0) {
      setGuessStatus(status);
      setDisableGuess(true);

      if (status === GuessStatus.Incorrect) {
        setTimeout(() => {
          setGuessStatus(GuessStatus.None);
          setDisableGuess(false);
          setInput("");
        }, 1500);
      }
    }
  };

  // Update game data
  const updateGameDataDB = React.useCallback((updatedGameData: GameData) => {
    const updatedGameDataWithIds = {
      ...updatedGameData,
      PlayerGuid: playerData.PlayerGuid
    }

    manageGame(updatedGameDataWithIds).then((gameRes: any) => {
      const updatedResData = {
        ...gameRes,
        Clue: JSON.parse(gameRes.Clue),
        GuessedLetters: gameRes.GuessedLetters ? JSON.parse(gameRes.GuessedLetters) : []
      };

      setGameData(updatedResData);
      updateGameDataLS(updatedResData);
    });
  }, [playerData.PlayerGuid, updateGameDataLS]);

  const gameOver = React.useCallback((result: Result, data: GameData) => {
    const updatedGameData: GameData = {
      ...data,
      IsGameOver: true,
      GameOverReason: result,
    };

    const updatedPlayerData: PlayerData = { 
      ...playerData,
      TotalScore: playerData.TotalScore + data.CurrentScore,
      Streak: (result === Result.Win) ? playerData.Streak + 1 : 0,
      GamesPlayed: playerData.GamesPlayed + 1
    }

    // Update state
    setInput(solutionLetter.toUpperCase());
    setShowGameOverDisplay(true);
    setGameData(updatedGameData);
    setPlayerData(updatedPlayerData);

    // Update LS
    updateGameDataLS(updatedGameData);
    updatePlayerDataLS(updatedPlayerData);

    // Update DB (with game data only, for now)
    updateGameDataDB(updatedGameData);

  }, [solutionLetter, updateGameDataDB, playerData, updateGameDataLS]);

  const useHint = (): void => {
    if (!gameData.IsHintUsed) {
      const updatedData: GameData = {
        ...gameData,
        IsHintUsed: true,
        CurrentScore: gameData.CurrentScore - 1,
      };

      showMinusOne();
      setGameData(updatedData);
      updateGameDataLS(updatedData);
    }
  };

  const updatedRemovedLetters = (letter: string): string => {
    return (
      removedLetters +
      " " +
      letter.toUpperCase() +
      " " +
      letter.toLowerCase()
    );
  };

  const openModal = (modalType: ModalTypes): void => {
    setShowModal(true);
    setModalType(modalType);
  };

  const makeGuess = (): void => {
    let updatedGameData: GameData = {
      ...gameData,
      GuessedLetters: [...JSON.parse(JSON.stringify(gameData.GuessedLetters)), input.toUpperCase()]
    };

    if (input.toUpperCase() === solutionLetter.toUpperCase()) {
      updateGuessStatus(GuessStatus.Correct, gameData.CurrentScore);
      gameOver(Result.Win, { ...updatedGameData });
    } else {
      const updatedCurrentScore = gameData.CurrentScore > 0 ? gameData.CurrentScore - 1 : 0;

      updatedGameData.CurrentScore = updatedCurrentScore;

      setRemovedLetters(updatedRemovedLetters(input));
      updateGuessStatus(GuessStatus.Incorrect, updatedCurrentScore);

      if (gameData.CurrentScore <= 1) {
        gameOver(Result.Lose, updatedGameData);
      } else {
        showMinusOne();
        setGameData(updatedGameData);
        updateGameDataLS(updatedGameData);
      }
    }
  };

  return (
    <div>
      <div className="header">
        <div className="streak">
          <svg
            key={"streak"}
            viewBox="0 0 1024 1024"
            fill="currentColor"
            height="1.5em"
            width="1.5em"
          >
            <path
              fill="gold"
              d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 00.6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0046.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"
            />
          </svg>
          <div className="total-score">{playerData ? playerData.TotalScore : 0}</div>
        </div>
        {GAME_TITLE}
        {showSecretIcon
          ? <div
              className={"help secret-icon"}
              onClick={() => {
                if(showAddClueForm || showJordanSecretPage) {
                  window.location.reload();
                }
                else {
                  switch (secretInput.toUpperCase()) {
                    case J_SANGRIA:
                      setShowAddClueForm(true);
                      break;
                    case CRYSTAL_BALL:
                      setShowJordanSecretPage(true);
                      break;
                  }
                }
              }}
            >{'!'}</div>
          : <div
              className={"help"}
              onClick={() => {
                openModal(ModalTypes.Help);
              }}
            >{'?'}</div>
        }
      </div>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel={modalType ?? undefined}
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            inset: "30px",
          },
        }}
      >
        <ModalContent
          modalType={modalType}
          closeModal={() => {
            setShowModal(false);
          }}
        />
      </Modal>
      <div className="flex-container">
        {!dataLoaded
          ? <BeatLoader speedMultiplier={0.5} />
          : <>
              {showAddClueForm
                ? <AddClue />
                : (showJordanSecretPage
                    ? <JordanSecretPage />
                    : <>
                        <div className="board">
                          <span id="wave-container" className="clue"></span>
                          <input className={guessStatus} value={input.toUpperCase()} readOnly />
                          {showGameOverDisplay && (
                            <span className="clue-subtext">
                              {ClueOfTheDay?.SolutionExplanation}
                            </span>
                          )}
                          {!showGameOverDisplay && gameData.IsHintUsed && (
                            <span className="clue-subtext hint">
                              {"Hint: " + ClueOfTheDay?.Hint}
                            </span>
                          )}
                        </div>
                        {showGameOverDisplay ? (
                          <>
                            <div className="game-over">
                              <div className="game-over-score">
                                <span className="your-score">Your score:</span>
                                <Stars
                                  gameData={gameData}
                                  showGameOverDisplay={showGameOverDisplay}
                                />
                              </div>
                            </div>
                            <Countdown
                              date={new Date().setHours(24, 0, 0, 0)}
                              renderer={({ formatted }) => {
                                return (
                                  <div className="countdown">
                                    Next clue in:
                                    <span className="time">
                                      {" " +
                                        formatted.hours +
                                        ":" +
                                        formatted.minutes +
                                        ":" +
                                        formatted.seconds}
                                    </span>
                                  </div>
                                );
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <div className="flex-container-2">
                              <div className="buttonBar">
                                <button className={"hint"} onClick={useHint}>
                                  Hint
                                </button>
                                <button
                                  className={input.length === 0 || disableGuess ? " disabled" : ""}
                                  onClick={makeGuess}
                                  disabled={input.length === 0 || disableGuess}
                                >
                                  Guess
                                </button>
                              </div>
                              <div className="stars-container">
                                <span className={'minus-one ' + (minusOneVisible ? '' : 'hidden')}>{"-1"}</span>
                                <Stars
                                  gameData={gameData}
                                  showGameOverDisplay={showGameOverDisplay}
                                />
                              </div>
                            </div>
                            <div className="keyboard">
                              <CustomKeyboard gameData={gameData} removedLetters={removedLetters} setInput={(letter: string) => {
                                setInput(letter);
                                setSecretInput(letter === '' ? '' : secretInput + letter);
                              }} />
                            </div>
                          </>
                        )}
                      </>
                  )
              }
            </>
        }
      </div>
    </div>
  );
}
